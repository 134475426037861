/* Include CSS here to customize styling of the application */
a.navbar-brand img {
    width: 311px;
    height: 64px;
}

.bg-dark {
    background-color: rgb(0,91,133) !important;
    box-shadow: 0 4px 10px 0px #cdcdcd;
}

.bg-dark-red {
    background-color: rgb(244,66,66) !important;
    box-shadow: 0 4px 10px 0px #cdcdcd;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.4);
}

.dropdown-item .nav-link {
    color: #16181b !important;
    padding: 0 0 !important; 
}

.navbar {
    padding-top:0;
    padding-bottom:0;
}

.amplify-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

div.card {
    box-shadow: 0 5px 5px 0px #dcdcdc;
}

.k-minus:after {
    content: "-" !important;
}

.k-plus:after {
    content: "+";
}